// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("D:\\Kodowanie\\MOJE\\Projekty\\gatsby-lksd-portfolio\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-en-js": () => import("D:\\Kodowanie\\MOJE\\Projekty\\gatsby-lksd-portfolio\\src\\pages\\index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-pl-js": () => import("D:\\Kodowanie\\MOJE\\Projekty\\gatsby-lksd-portfolio\\src\\pages\\index.pl.js" /* webpackChunkName: "component---src-pages-index-pl-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "D:\\Kodowanie\\MOJE\\Projekty\\gatsby-lksd-portfolio\\.cache\\data.json")

